import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  BlockBetween,
  BlockDes,
} from "../../components/Component";
import YearSelector from "./YearSelector";
import ChartOne from "./ChartOne";
import ChartTwo from "./ChartTwo";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllIndicatorsList } from "../auth/store/auth.action";
import { selectAllIndicators } from "../auth/store/auth.slice";
import Select from "react-select";
import { set } from "lodash";

const Homepage = () => {
  const [sm, updateSm] = useState(false);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useSelector(selectAllIndicators);
  const [indicatorSelected, setIndicatorSelected] = useState(
    data?.slice(0, 4).map((item) => ({ value: item.id, label: item.name })) || []
  );

  const onChange = (selectedYear) => {
    setSelectedYear(selectedYear);
  };

  useEffect(() => {
    dispatch(fetchAllIndicatorsList({ all: true }));
  }, [dispatch]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 200, // Set your desired width here
    }),
  };
  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Welcome to Oromia Health Bureau SBC Dashboard
              </BlockTitle>
            </BlockHeadContent>
            {isError && <div className="alert alert-danger">{isError}</div>}
            {isLoading ? (
              <div className="nk-ck-sm d-flex align-items-center justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="form-group d-flex justify-content-center align-items-center gap-4">
                <YearSelector selectedYear={selectedYear} onChange={onChange} />
                <div className="w-full ">
                  <span>Select Indicator _ ()</span>

                  <Select
                    isMulti
                    name="indicators"
                    options={
                      data?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      })) || []
                    }
                    onChange={(selectedOption) => setIndicatorSelected(selectedOption)}
                    styles={customStyles}
                    className="basic-multi-select"
                    classNamePrefix="select Indicators"
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="indicator">Choose Indicator</label>
                  <div className="form-control-wrap">
                    <select
                      className="form-control"
                      id="indicator"
                      name="indicator"
                      onChange={(e) => setIndicatorSelected(e.target.value)}
                    >
                      {data?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}
              </div>
            )}

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}></div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h4">Annually Report</BlockTitle>
              {/* <BlockDes>
                <p>Description here</p>
              </BlockDes> */}
            </BlockHeadContent>
          </BlockHead>
          <Row className="g-gs">
            {indicatorSelected?.map((indicator) => (
              <ChartOne title={indicator.label} currentYear={selectedYear} parentId={indicator.value} />
            ))}

            {/* {chartData.map((chart) => (
              <Col md={6}>
                <PreviewCard>
                  <div className="card-head">
                    <h6 className="title">{chart.title}</h6>
                  </div>
                  <div className="nk-ck-sm">
                    <BarChartExample data={chart.data} />
                  </div>
                </PreviewCard>
              </Col>
            ))} */}
          </Row>
        </Block>
        {/* <Block>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h4">Annually Report</BlockTitle>
              <BlockDes>
                <p>Description here</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <ChartTwo currentYear={selectedYear} title={"SBC materials produced / received and distributed"} />
        </Block> */}
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
