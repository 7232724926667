import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { Link } from "react-router-dom";
import { ModalBody, Modal, Badge } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../../components/Component";
import { showFormatedDate } from "../../../../utils/Utils";
import { useDispatch } from "react-redux";
import { fetchCurrentProfileData, fetchIndicatorsList, fetchReportSessions } from "../../../auth/store/auth.action";
import { useSelector } from "react-redux";
import {
  selectCurrentProfileData,
  selectCurrentUser,
  selectIndicatorsList,
  selectReportSessionsList,
} from "../../../auth/store/auth.slice";
import { ProfileForm } from "./ProfileData/ProfileForm";
import { PropagateLoader } from "react-spinners";
import ReportForm from "../Form/ReportForm";
import { BsUmbrellaFill } from "react-icons/bs";
import { GrCheckmark } from "react-icons/gr";
import { AggregateForm } from "./Aggregate/AggregateForm";

const Header = (props) => {
  const indicatorsList = useSelector(selectIndicatorsList);

  return (
    <div className="steps clearfix">
      <ul>
        {Array.from(Array(indicatorsList.length + 1).keys()).map((i) => (
          <li key={i} className={props.current >= i + 1 ? "first done" : "first"}>
            <a href={"#wizard-01-h-" + i + 1} onClick={(ev) => ev.preventDefault()}>
              <h5>Qn {i + 1}</h5>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const config = {
  before: Header,
};

export const ZoneReports = () => {
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [profileModal, setProfileModal] = useState({
    edit: false,
    add: false,
  });

  const indicatorsList = useSelector(selectIndicatorsList);
  const { report, isLoading, isError } = useSelector(selectReportSessionsList);
  const user = useSelector(selectCurrentUser);
  const { profileData: reportProfile, isLoading: profileLoader } = useSelector(selectCurrentProfileData);
  const [isAggregated, setIsAggregated] = useState(false);

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    setProfileModal({ edit: false, add: false });
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  let currentYear = new Date(Date.now()).getFullYear();

  function cleanAggregatedForm() {
    setIsAggregated(false);
  }

  const getReportLevel = (level, user) => {
    if (level === 1) {
      return user?.region;
    } else if (level === 2) {
      return user?.zone;
    } else if (level === 3) {
      return user?.woreda;
    } else if (level === 4) {
      return user?.health_facility;
    } else {
      return user?.community;
    }
  };

  useEffect(() => {
    let level_id = getReportLevel(user?.level, user)?.id;

    dispatch(fetchIndicatorsList(2));
    if (user) {
      dispatch(fetchCurrentProfileData({ year: currentYear, level: user?.level, level_id: level_id }));
    }
  }, [user]);

  useEffect(() => {
    dispatch(fetchReportSessions({ id: 2, currentPage }));
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <Head title="Project List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Reports</BlockTitle>
              <BlockDes className="text-soft">You have total {report?.totalItems} Reports</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>

                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3 d-flex flex-sm-row flex-column">
                    {profileLoader ? (
                      <Button color="primary">
                        <span>loading ...</span>
                      </Button>
                    ) : (
                      reportProfile?.length == 0 &&
                      user?.level == 2 && (
                        <li className="nk-block-tools-opt" onClick={() => setProfileModal({ add: true })}>
                          <Button color="primary">
                            <Icon name="plus"></Icon>
                            <span>Add Profile Data</span>
                          </Button>
                        </li>
                      )
                    )}
                    {user?.level == 2 && (
                      <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                        <Button color="primary">
                          <Icon name="plus"></Icon>
                          <span>Submit Report</span>
                        </Button>
                      </li>
                    )}
                    <li className="nk-block-tools-opt" onClick={() => setIsAggregated(true)}>
                      <Button color="success ">
                        <BsUmbrellaFill />
                        <span className="ps-2">Aggregate</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          {/* Loading Component */}
          <>
            {isLoading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="p-2"></span>
                <span>
                  <PropagateLoader className="text-center" color="#3693d6" />
                </span>
              </div>
            )}
            {isError && <span className="text-danger">Something went wrong, try again</span>}
          </>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead className="nk-tb-item nk-tb-head">
                <DataTableRow className="nk-tb-col-check">
                  <span className="sub-text">S.N.</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Report from </span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Report Month</span>
                </DataTableRow>

                <DataTableRow size="lg">
                  <span className="sub-text">Reported by</span>
                </DataTableRow>

                <DataTableRow size="lg">
                  <span className="sub-text">Reported at</span>
                </DataTableRow>
              </DataTableHead>

              {/* rendering data */}
              {report?.reportSessions?.length > 0
                ? report?.reportSessions?.map((item, index) => {
                    // var days = setDeadlineDays(item?.reported_at);
                    let levelObj = item?.report_level;

                    if (item?.report_level && typeof item?.report_level == "string") {
                      let level = item?.report_level;
                      levelObj = JSON.parse(level);
                    }

                    return (
                      <DataTableItem key={item?.id}>
                        <DataTableRow className="nk-tb-col-check">
                          <span className="sub-text">{index + 1}</span>
                        </DataTableRow>

                        <DataTableRow>
                          <a
                            href="#title"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="project-title"
                          >
                            <div className="project-info">
                              <Link to={`${process.env.PUBLIC_URL}/report-details/${item?.id}`}>
                                <h6 className="title">{levelObj?.name}</h6>
                              </Link>
                            </div>
                          </a>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span className="project-title">
                            {item?.month}, {item?.year || "2024"}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>
                            {item?.user?.firstName} {item?.user?.lastName}
                          </span>
                        </DataTableRow>

                        <DataTableRow size="mb">
                          <Badge className="badge-dim">
                            <Icon name="clock"></Icon>
                            <span>{showFormatedDate(item?.reported_at)}</span>
                          </Badge>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <Link to={`${process.env.PUBLIC_URL}/report-details/${item?.id}`}>Show</Link>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {report?.reportSessions?.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={report?.totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                !isLoading && (
                  <div className="text-center">
                    <span className="text-silent">No Reports found, if something trouble contact system admin</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>

        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="xl">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Submit reports</h5>
              <div className="mt-4">
                <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                  <ReportForm
                    setModal={setModal}
                    indicatorsList={indicatorsList}
                    level={2}
                    url={"/zone-report"}
                    config={config}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* profile data */}
        <Modal
          isOpen={profileModal.add}
          toggle={() => setProfileModal({ add: false })}
          className="modal-dialog-centered"
          size="xl"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add Zone Profile Data</h5>
              <div className="mt-4">
                <ProfileForm level={2} setProfileModal={setProfileModal} url={"zone-report"} />
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* aggregation  */}
        <Modal isOpen={isAggregated} toggle={() => setIsAggregated(false)} className="modal-dialog-centered" size="xl">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                cleanAggregatedForm();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <div className="d-flex align-items-center gap-3 text-success">
                <BsUmbrellaFill size={20} />
                <h5 className="title">Aggregation tool</h5>
              </div>
              <div className=" pt-5 d-flex align-items-center gap-1">
                <span>
                  <GrCheckmark />
                </span>
                <span className="text-dark">Zonal (under your region)</span>
              </div>
              <div className="mt-4">
                <AggregateForm level={2} />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default ZoneReports;
