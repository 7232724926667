import React, { useEffect, useState } from "react";
import { Button, Col, Icon, RSelect } from "../../../../components/Component";
import { Input, Row } from "reactstrap";
import { monthList, ageCategory } from "../ProjectData";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, selectLocalReportList } from "../../../auth/store/auth.slice";
import { useForm } from "react-hook-form";
import { fetchReportSessions, saveReport } from "../../../auth/store/auth.action";
import { BsInfoCircle } from "react-icons/bs";
import { Tooltip } from "reactstrap";
import { values } from "lodash";

function TooltipItem(props) {
  const { id, content } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <Button className="" id={"Tooltip-" + id}>
        <BsInfoCircle />
      </Button>
      <Tooltip
        className={"p-0"}
        placement={"top"}
        isOpen={tooltipOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
        style={{ backgroundColor: "#f1f1f1", color: "#222", width: "100%", textAlign: "start" }}
      >
        {content}
      </Tooltip>
    </span>
  );
}

const ReportForm = ({ level, setModal, indicatorsList }) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [filteredMonthList, setFilteredMonthList] = useState([]);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm();

  const getReportLevel = (level, user) => {
    if (level === 1) {
      return user?.region;
    } else if (level === 2) {
      return user?.zone;
    } else if (level === 3) {
      return user?.woreda;
    } else if (level === 4) {
      return user?.health_facility;
    } else {
      return user?.community;
    }
  };
  const submitForm = (data) => {
    const reportList = transformObjectToArray(data, indicatorsList?.length, user, level);

    const reportSession = {
      level: level,
      month: data.month,
      year: data.year,
      report_level: getReportLevel(level, user),
      level_id: getReportLevel(level, user)?.id,
      userId: user?.id,
      reported_at: Date.now(),
    };

    dispatch(saveReport({ session: reportSession, report: reportList }));

    setTimeout(() => {
      dispatch(fetchReportSessions({ id: level, currentPage: 1 }));
    }, 1000);

    setModal({ edit: false, add: false });
    reset();
  };
  const years = Array.from({ length: currentYear - 2023 }, (_, index) => currentYear - index).map((year) => ({
    value: year,
    label: year,
  }));

  useEffect(() => {
    if (currentYear === parseInt(watch("year"))) {
      const currentMonth = new Date().getMonth();
      const filteredMonths = monthList.filter((month, index) => index <= currentMonth);
      setFilteredMonthList(filteredMonths);
    } else {
      setFilteredMonthList(monthList);
    }
  }, [watch("year")]);

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      {indicatorsList?.length > 0 ? (
        <Row className="gy-4">
          <Col md="6">
            <div className="form-group">
              <label className="form-label" htmlFor="year">
                Select Year <span className="text-primary"> ({watch("year")})</span>
              </label>
              <div className="form-control-wrap">
                <select className="form-control" id="year" name="year" {...register("year", { required: true })}>
                  <option value="" className="text-center">
                    __ Select Report Year __
                  </option>
                  {years?.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {errors.year && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label" htmlFor="month">
                Select Month <span className="text-primary"> ({watch("year")})</span>
              </label>
              <div className="form-control-wrap">
                <select className="form-control" id="month" name="month" {...register("month", { required: true })}>
                  <option value="" className="text-center">
                    __ Select Report Month __
                  </option>
                  {filteredMonthList.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {errors.month && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <div>No Indicators Found</div>
      )}

      <div className="content clearfix ">
        <Row>
          {indicatorsList?.map((indicator, i) => (
            <Col xs={12} md={6} className="pt-4 fs-6">
              <div>
                <span>{indicator.name}</span>
                <TooltipItem id={i} content={indicator?.description} />
              </div>
              <input
                type="number"
                id={`parentId${i}`}
                className="form-control"
                name={`parentId${i}`}
                min="0"
                {...register(`parentId${i}`, { required: true })}
                value={indicator.id}
                hidden
              />
              <input
                type="number"
                id={`indicatorId${i}`}
                className="form-control"
                name={`indicatorId${i}`}
                min="0"
                {...register(`indicatorId${i}`, { required: true })}
                value={indicator.id}
                hidden
              />

              <div className="form-group">
                <div className="form-control-wrap">
                  <input
                    type="number"
                    id="total"
                    className={`form-control mt-1 ${errors[`total${i}`] && errors[`total${i}`] ? "border-danger" : ""}`}
                    name={`total${i}`}
                    pattern="[0-9]*"
                    min="0"
                    {...register(`total${i}`, {
                      required: true,
                      pattern: /^[0-9]*$/,
                    })}
                    inputmode="numeric"
                  />
                  {errors[`total${i}`] && (
                    <small style={{ fontSize: "12px" }} className="text-danger">
                      This field is required, only Numbers are supported.
                    </small>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      {indicatorsList?.length > 0 && (
        <div className="actions clearfix">
          <ul className="d-flex justify-content-end">
            <li>
              <Button color="primary" type="submit">
                Submit
              </Button>
            </li>
          </ul>
        </div>
      )}
    </form>
  );
};

function transformObjectToArray(obj, length, user, level) {
  const result = [];
  const keys = Object.keys(obj);

  const getLevelObject = (level, user) => {
    if (level === 1) {
      return { regionId: user?.region?.id };
    } else if (level === 2) {
      return { zoneId: user?.zone?.id };
    } else if (level === 3) {
      return { woredaId: user?.woreda?.id };
    } else if (level === 4) {
      return { healthFacilityId: user?.health_facility?.id };
    } else {
      return { communityId: user?.community?.id };
    }
  };

  for (let i = 0; i < length; i++) {
    // preparing the real key
    const total = `total${i}`;
    const parentId = `parentId${i}`;
    const indicatorId = `indicatorId${i}`;

    // grabbing the values from the object
    const totalCount = obj[total] || 0;
    const parentIdCount = obj[parentId] || 0;
    const indicatorIdCount = obj[indicatorId] || 0;

    result.push({
      total: totalCount,
      parentId: parentIdCount,
      indicatorId: indicatorIdCount,
      ...getLevelObject(level, user),
    });
  }

  return result;
}

export default ReportForm;
